import { $themeBreakpoints } from '@themeConfig'
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
  },
  actions: {
    fetchActivityTypes(ctx, d) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/activity-types', { params: d })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCountries(ctx, d) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/countries', { params: d })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchPriceGroups(ctx, d) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/price-groups', { params: d })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/users', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchBankAccounts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/bank-accounts', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCurrencyTypes(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/currencytypes', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUnitTypes(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/unittypes', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTaxTypes(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/taxtypes', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    suggestProducts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/suggestproducts', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    suggestCustomers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/suggestcustomers', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCustomers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/customers', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSingleCustomer(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/customers/${queryParams.id}`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProduct(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/products/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // notifications
    fetchNotifications(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/notifications', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    notificationMarkAsReaded(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/notifications-mark-as-readed', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    changeRole(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/change-role', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRoles(ctx, d) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/roles', { params: d })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
