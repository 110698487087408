import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import Editor from 'vue-editor-js'

import i18n from '@/libs/i18n'
import moment from 'moment'
import router from './router'
import store from './store'
import App from './App.vue'
import staticParams from './static-params'
import dynamicParams from './dynamic-params'
// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Axios Mock Adapter
import '@/@fake-db/db'

Vue.use(Editor)
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

moment.locale(i18n.locale)
Vue.config.productionTip = false
Vue.prototype.$staticParams = staticParams
Vue.prototype.$dynamicParams = dynamicParams
Vue.prototype.$moment = moment
Vue.prototype.$format_datetime = datetime => moment(datetime).format('LLL')
Vue.prototype.$format_date = date => moment(date).format('LL')
new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
