export default [

  // *=============DASHBOARD=============
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/crm/dashboard/Dashboard.vue'),
    meta: {
      resource: 'm_dashboard',
      action: 'read',
    },
  },
  // *================================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/users/list',
    name: 'user-list',
    component: () => import('@/views/crm/user/users-list/UsersList.vue'),
    meta: {
      resource: 'user_edit',
      action: 'read',
    },
  },
  {
    path: '/apps/users/view/:id',
    name: 'apps-users-view',
    component: () => import('@/views/apps/user/users-view/UsersView.vue'),
    meta: {
      resource: 'user_edit',
      action: 'read',
    },
  },
  {
    path: '/users/edit/:id',
    name: 'users-edit',
    component: () => import('@/views/crm/user/users-edit/UsersEdit.vue'),
    meta: {
      resource: 'user_edit',
      action: 'read',
    },
  },
  // *===============================================---*
  // *--------- CUSTOMER ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/customers/list',
    name: 'customers-list',
    meta: {
      resource: 'm_customer',
      action: 'read',
    },
    component: () => import('@/views/crm/customer/customers-list/CustomerList.vue'),
  },
  {
    path: '/customers/edit/:id',
    name: 'customers-edit',
    meta: {
      resource: 'm_customer',
      action: 'read',
    },
    component: () => import('@/views/crm/customer/customers-edit/CustomersEdit.vue'),
  },
  // *===============================================---*
  // *--------- PRODUCT ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/products/list',
    name: 'products-list',
    component: () => import('@/views/crm/product/products-list/ProductList.vue'),
    meta: {
      resource: 'm_product',
      action: 'read',
    },
  },
  {
    path: '/products/edit/:id',
    name: 'products-edit',
    component: () => import('@/views/crm/product/products-edit/ProductsEdit.vue'),
    meta: {
      resource: 'product_edit',
      action: 'read',
    },
  },
  // *===============================================---*
  // *--------- BRAND ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/brands/list',
    name: 'brands-list',
    component: () => import('@/views/crm/product/product-brands/BrandList.vue'),
    meta: {
      resource: 'm_product',
      action: 'read',
    },
  },
  {
    path: '/brands/edit/:id',
    name: 'brands-edit',
    component: () => import('@/views/crm/product/product-brands/BrandEdit.vue'),
    meta: {
      resource: 'product_edit',
      action: 'read',
    },
  },
  // *===============================================---*
  // *--------- Price groups ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/price-groups/list',
    name: 'price-groups-list',
    component: () => import('@/views/crm/price-groups/PriceGroupList.vue'),
    meta: {
      resource: 'm_product',
      action: 'read',
    },
  },
  {
    path: '/price-groups/edit/:id',
    name: 'price-groups-edit',
    component: () => import('@/views/crm/price-groups/PriceGroupList.vue'),
    meta: {
      resource: 'product_edit',
      action: 'read',
    },
  },
  // *===============================================---*
  // *--------- CATEGORIES ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/categories/list',
    name: 'categories-list',
    component: () => import('@/views/crm/product/product-categories/CategoryList.vue'),
    meta: {
      resource: 'm_product',
      action: 'read',
    },
  },
  {
    path: '/categories/edit/:id',
    name: 'categories-edit',
    component: () => import('@/views/crm/product/product-categories/CategoryEdit.vue'),
    meta: {
      resource: 'product_edit',
      action: 'read',
    },
  },
  // *===============================================---*
  // Settings & roles
  {
    path: '/setting/roles',
    name: 'settings-roles',
    component: () => import('@/views/crm/role/RoleList.vue'),
    meta: {
      resource: 'm_config',
      action: 'read',
    },
  },
  // *===============================================---*
  // Settings & payment settings
  {
    path: '/setting/payment',
    name: 'settings-payment',
    component: () => import('@/views/crm/payment-settings/PaymentSettings.vue'),
    meta: {
      resource: 'm_config',
      action: 'read',
    },
  },
  // *============================
  // Notifications
  // *===========================
  {
    path: '/notifications',
    name: 'notifications',
    component: () => import('@/views/crm/notification/NotificationPage.vue'),
  },
  {
    path: '/notifications/:id',
    name: 'notification-detail',
    component: () => import('@/views/crm/notification/NotificationDetail.vue'),
  },
  {
    path: '/notifications/add',
    name: 'notifications-add',
    component: () => import('@/views/crm/notification/NotificationAdd.vue'),
    meta: {
      resource: 'notification_send',
      action: 'read',
    },
  },
  // SHOP
  // *============================
  // Menu
  // *===========================
  {
    path: '/shop/menu',
    name: 'shop-menu',
    component: () => import('@/views/crm/shop/menu/MenuList.vue'),
  },
  // Page Builder
  // *===========================
  {
    path: '/shop/page-builder',
    name: 'page-builder',
    component: () => import('@/views/crm/shop/pagebuilder/PageBuilder.vue'),
  },
  // Payments
  // *===========================
  {
    path: '/payments/payment-list',
    name: 'payment-list',
    component: () => import('@/views/crm/payments/PaymentList.vue'),
  },
  {
    path: '/payments/detail/:id',
    name: 'payment-detail',
    component: () => import('@/views/crm/payments/PaymentDetail.vue'),
  },
  // Orders
  // *===========================
  {
    path: '/order/order-list',
    name: 'order-list',
    component: () => import('@/views/crm/order/OrderList.vue'),
  },
  {
    path: '/order/detail/:id',
    name: 'order-detail',
    component: () => import('@/views/crm/order/OrderDetail.vue'),
  },
  // SHOP

  // REPORTS
  // *===========================

  {
    path: '/reports/order-list',
    name: 'reports-order-list',
    component: () => import('@/views/crm/reports/order-reports/OrderList.vue'),
  },
  {
    path: '/reports/order-detail/:id',
    name: 'reports-order-detail',
    component: () => import('@/views/crm/reports/order-reports/OrderDetail.vue'),
  },
  {
    path: '/reports/reports-search',
    name: 'reports-search',
    component: () => import('@/views/crm/reports/search-reports/SearchReports.vue'),
  },
  {
    path: '/reports/login-reports',
    name: 'login-reports',
    component: () => import('@/views/crm/reports/login-reports/LoginReports.vue'),
  },
  // Invoice
  {
    path: '/apps/invoice/list',
    name: 'apps-invoice-list',
    component: () => import('@/views/apps/invoice/invoice-list/InvoiceList.vue'),
  },
  {
    path: '/apps/invoice/preview/:id',
    name: 'apps-invoice-preview',
    component: () => import('@/views/apps/invoice/invoice-preview/InvoicePreview.vue'),
  },
  {
    path: '/apps/invoice/add/',
    name: 'apps-invoice-add',
    component: () => import('@/views/apps/invoice/invoice-add/InvoiceAdd.vue'),
  },
  {
    path: '/apps/invoice/edit/:id',
    name: 'apps-invoice-edit',
    component: () => import('@/views/apps/invoice/invoice-edit/InvoiceEdit.vue'),
  },
  // *===============================================---*
  // *--------- Mail ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/emails/categories',
    name: 'email-categories',
    component: () => import('@/views/crm/email/category-list/CategoryList.vue'),
    meta: {
      resource: 'm_email_category',
      action: 'read',
    },
  },
  {
    path: '/emails/address/:category_id',
    name: 'email-address',
    component: () => import('@/views/crm/email/email-address-list/EmailAddressList.vue'),
    meta: {
      resource: 'm_email_category',
      action: 'read',
    },
  },

]
