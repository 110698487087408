export default {
  productCategoriesData: [],
  productBrandsData: [],
  getCategoryName(id) {
    let selectedName = ''
    // eslint-disable-next-line consistent-return
    this.productCategoriesData.forEach(val => {
      if (val.value === id) {
        selectedName = val.text
      }
    })
    return selectedName
  },
  getBrandName(id) {
    let selectedName = ''
    // eslint-disable-next-line consistent-return
    this.productBrandsData.forEach(val => {
      if (val.value === id) {
        selectedName = val.text
      }
    })
    return selectedName
  },
}
